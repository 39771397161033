<template>
    <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.6364 3.94737V3.94737C12.6364 2.31958 11.3168 1 9.68899 1H4C2.34315 1 1 2.34315 1 4V12C1 13.6569 2.34315 15 4 15H9.689C11.3168 15 12.6364 13.6804 12.6364 12.0526V12.0526M6.09091 8H17M17 8L14.8182 5.78947M17 8L14.8182 10.2105"
            stroke="#C8C8C8"
            stroke-width="2"
            stroke-linecap="round"
        />
    </svg>
</template>
