import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/style.css";
import { MaskInput } from "vue-3-mask";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import VueTheMask from "vue-the-mask";
import { createYmaps } from 'vue-yandex-maps';

const app = createApp(App);

app.use(router)
    .component("MaskInput", MaskInput)
    .component("VueDatePicker", VueDatePicker)
    .use(VueTheMask)
    .use(createYmaps({
        apikey: '3e3c373b-0310-4ae7-b19c-5decd62ce8ec',
    }))
    .mount("#app");
