<template>
    <footer class="footer">
        <div class="container">
            <div class="footer__container">
                <div class="footer__l col">
                    <div class="stroke mm">
                        <h4 class="stroke__title">
                            <span>{{ title }}</span>
                        </h4>
                        <div class="stroke__icons">
                            <a
                                v-for="location in locations"
                                :key="location.id"
                                :href="location.href"
                                class="stroke__icon"
                                target="_blank"
                            >
                                <img :src="require(`@/assets/img/icons/${location.img}`)" alt="location" />
                            </a>
                        </div>
                    </div>
                    <div class="stroke" v-for="stroke in strokes" :key="stroke.id">
                        <div class="stroke__str-ref" v-if="stroke.id === 1">
                            <div class="stroke__text">
                                {{ stroke.text }}
                            </div>
                            <div class="stroke__subscribes">
                                <a
                                    :href="social.href"
                                    class="stroke__social"
                                    target="_blank"
                                    v-for="social in socials"
                                    :key="social.id"
                                    ><img :src="require(`@/assets/img/svg/${social.img}`)" alt="social" />
                                </a>
                            </div>
                        </div>
                        <a
                            :href="stroke.id === 3 ? $route.meta.refTo : stroke.href"
                            class="stroke__ref"
                            :target="stroke.id !== 3 ? '_blank' : ''"
                            v-else
                            ><div class="stroke__text">
                                {{ stroke.id === 3 ? $route.meta.refName : stroke.text }}
                            </div>
                            <div class="stroke__arrow">
                                <img src="@/assets/img/svg/arrow-ref.svg" alt="arrow" /></div
                        ></a>
                    </div>
                </div>
                <div class="footer__r col">
                    <div class="stroke md">
                        <h4 class="stroke__title">
                            <span>{{ title }}</span>
                        </h4>
                        <div class="stroke__icons">
                            <a
                                v-for="location in locations"
                                :key="location.id"
                                :href="location.href"
                                class="stroke__icon"
                                target="_blank"
                            >
                                <img :src="require(`@/assets/img/icons/${location.img}`)" alt="location" />
                            </a>
                        </div>
                    </div>
                    <div class="stroke">
                        <a :href="`tel:+${tel.replace(/[^0-9]/gi, '')}`" class="stroke__tel">{{ tel }}</a>
                    </div>
                    <div class="stroke">
                        <a :href="`mailto:${email}`" class="stroke__email">{{ email }}</a>
                    </div>
                    <div class="stroke">
                        <div class="stroke__address">
                            {{ address }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    data() {
        return {
            email: "feedback@tubatay.com",
            title: "Как нас найти",
            tel: "+7 (843) 249-41-41",
            address: `ООО "ТЮБЕТЕЙ УК" / ОГРН: 1181690082573 / тел: 249-41-41.\n420107, РТ, Казань, ул.Ш.Марджани, д.4, помещ.1002`,
            strokes: [
                {
                    id: 1,
                    text: `Подпишись, чтобы\n быть в курсе новостей`,
                    href: "https://t.me/Tubeteybot?start=link_gtW1wFpRKh",
                },
                {
                    id: 2,
                    text: `Подписаться на чат-бот в телеграм,\n чтобы быть в курсе всех акций`,
                    href: "https://t.me/Tubeteybot?start=link_gtW1wFpRKh",
                },
                {
                    id: 3,
                    text: "",
                    href: "",
                },
            ],
            socials: [
                {
                    id: 1,
                    img: "VK.svg",
                    href: "https://vk.com/tubatay",
                },
                {
                    id: 2,
                    img: "Telegram.svg",
                    href: "https://t.me/tubataykzn",
                },
            ],
            locations: [
                {
                    id: 1,
                    img: "2gis.webp",
                    href: "https://go.2gis.com/kP7Y6",
                },
                {
                    id: 2,
                    img: "location.webp",
                    href: "https://yandex.ru/maps/-/CCUYJIC73D",
                },
            ],
        };
    },
};
</script>

<style scoped>
.footer {
    background-color: var(--green);
    padding: 45px 0 60px;
    position: relative;
}
@media (max-width: 768px) {
    .footer {
        padding: 40px 0;
    }
}
.footer__container {
    display: flex;
}
@media (max-width: 768px) {
    .footer__container {
        flex-direction: column;
    }
}
.footer__l {
    border-right: 1px solid rgba(236, 244, 239, 0.2);
    padding-right: 110px;
}
.footer__l .stroke {
    justify-content: space-between;
}
@media (max-width: 768px) {
    .footer__l .stroke:first-child,
    .footer__l .stroke:nth-child(2) {
        border-bottom: 1px solid rgba(236, 244, 239, 0.2);
        padding-bottom: 20px;
    }
}
@media (max-width: 768px) {
    .footer__l {
        padding-right: 0;
        border: none;
    }
}
.footer__r {
    padding-left: 80px;
}
@media (max-width: 768px) {
    .footer__r {
        padding-left: 0;
    }
    .footer__r .stroke:last-child {
        margin-top: 10px !important;
    }
}
.footer__l .stroke:not(:first-child),
.footer__l .stroke:not(:last-child),
.footer__r .stroke:not(:first-child),
.footer__r .stroke:not(:last-child) {
    margin-top: 30px;
}
@media (max-width: 768px) {
    .footer__l .stroke:not(:first-child),
    .footer__l .stroke:not(:last-child),
    .footer__r .stroke:not(:first-child),
    .footer__r .stroke:not(:last-child) {
        margin-top: 20px;
    }
}
.footer .col {
    flex: 0 0 50%;
}
.footer .stroke {
    display: flex;
    align-items: center;
}
.footer .stroke__text {
    font-size: 14px;
    line-height: 22px;
    color: #fff;
    font-family: "Nunito Sans SemiBold";
    white-space: pre-line;
}
@media (max-width: 768px) {
    .footer .stroke__text {
        line-height: 24px;
    }
}
.footer .stroke__title {
    font-size: 16px;
    line-height: 24px;
    color: #fff;
}
@media (max-width: 768px) {
    .footer .stroke__title {
        font-size: 14px;
        line-height: 24px;
        font-family: "Nunito Sans SemiBold";
    }
}
.footer .stroke__ref,
.footer .stroke__str-ref {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 1 auto;
}
.footer .stroke__ref:hover,
.footer .stroke__ref:focus {
    opacity: 0.5;
    transition: 0.2s ease 0.2s;
}
@media (max-width: 768px) {
    .footer .stroke__ref {
        font-size: 12px;
        line-height: 18px;
    }
    .footer .stroke__ref p {
        font-family: "Nunito Sans Light";
    }
}
.footer .stroke__subscribes {
    display: flex;
}
.footer .stroke__social {
    border: 2px solid #ecf4ef;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    margin-left: 15px;
}
.footer .stroke__social:hover,
.footer .stroke__social:focus {
    opacity: 0.5;
    transition: 0.2s ease 0.2s;
}
.footer .stroke__social:last-child img {
    margin-right: 5px;
}
.footer .stroke__arrow {
    width: 27px;
    height: 18px;
}
.footer .stroke__arrow img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.footer .stroke__icons {
    display: flex;
    margin-left: 30px;
}
.footer .stroke__icon {
    width: 44px;
    height: 44px;
    margin-left: 20px;
    border-radius: 50%;
    overflow: hidden;
}
.footer .stroke__icon:hover,
.footer .stroke__icon:focus {
    opacity: 0.5;
    transition: 0.2s ease 0.2s;
}
.footer .stroke__icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.footer .stroke__tel,
.footer .stroke__email {
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    font-family: "Nunito Sans SemiBold";
}
.footer .stroke__tel:hover,
.footer .stroke__tel:focus,
.footer .stroke__email:hover,
.footer .stroke__email:focus {
    opacity: 0.5;
    transition: 0.2s ease 0.2s;
}
@media (max-width: 768px) {
    .footer .stroke__tel,
    .footer .stroke__email {
        font-size: 12px;
        line-height: 18px;
    }
}
.footer .stroke__address {
    font-size: 10px;
    line-height: 16px;
    color: #f4f4f4;
    font-family: "Nunito Sans Light";
    opacity: 0.3;
    white-space: pre;
}
@media (max-width: 768px) {
    .footer .stroke__address {
        font-size: 8px;
        line-height: 12px;
        font-family: "Nunito Sans ExtraLight";
    }
}
</style>
