import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        meta: {
            title: "Главная",
            headerClass: "_blur",
            refName: "Заказать доставку",
            refTo: "/menu",
        },
    },
    {
        path: "/stocks",
        name: "stocks",
        component: () => import("../views/StocksView.vue"),
        meta: {
            title: "Акция",
            headerClass: "",
            refName: "Заказать доставку",
            refTo: "/menu",
        },
    },
    {
        path: "/stock/:id",
        name: "stock",
        component: () => import("../views/StockIdView.vue"),
        meta: {
            title: "Акция",
            headerClass: "",
            refName: "Заказать доставку",
            refTo: "/menu",
            back: true,
        },
    },
    {
        path: "/tourists",
        name: "tourists",
        component: () => import("../views/TouristsView.vue"),
        meta: {
            title: "Туристам",
            headerClass: "",
            refName: "Заказать доставку",
            refTo: "/menu",
        },
    },
    {
        path: "/restaraunts",
        name: "restaraunts",
        component: () => import("../views/RestarauntsView.vue"),
        meta: {
            title: "Адреса ресторанов",
            headerClass: "_delivery",
            refName: "Заказать доставку",
            refTo: "/menu",
            menuDelivery: true,
        },
    },
    {
        path: "/menu",
        name: "menu",
        component: () => import("../views/MenuView.vue"),
        meta: {
            title: "Заказать",
            headerClass: "_delivery",
            refName: "На главную",
            refTo: "/",
            profile: true,
            back: true,
            menuDelivery: true,
        },
    },
    {
        path: "/about-us",
        name: "about-us",
        component: () => import("../views/AboutUsView.vue"),
        meta: {
            title: "О нас",
            headerClass: "",
            refName: "Заказать доставку",
            refTo: "/menu",
        },
    },
    {
        path: "/contacts",
        name: "contacts",
        component: () => import("../views/ContactsView.vue"),
        meta: {
            title: "Контакты",
            headerClass: "",
            refName: "Заказать доставку",
            refTo: "/menu",
        },
    },
    {
        path: "/delivery",
        name: "delivery",
        component: () => import("../views/DeliveryView.vue"),
        meta: {
            title: "Условия доставки",
            headerClass: "_delivery",
            refName: "На главную",
            refTo: "/",
            menuDelivery: true,
        },
    },
    {
        path: "/discounts",
        name: "discounts",
        component: () => import("../views/DiscountsView.vue"),
        meta: {
            title: "Скидки",
            headerClass: "",
            refName: "Заказать доставку",
            refTo: "/menu",
        },
    },
    {
        path: "/invest",
        name: "invest",
        component: () => import("../views/InvestView.vue"),
        meta: {
            title: "Инвестиции",
            headerClass: "",
            refName: "Заказать доставку",
            refTo: "/menu",
        },
    },
    {
        path: "/details",
        name: "details",
        component: () => import("../views/DetailsView.vue"),
        meta: {
            title: "Реквизиты организаций",
            headerClass: "_delivery",
            refName: "На главную",
            refTo: "/",
        },
    },
    {
        path: "/profile",
        name: "profile",
        component: () => import("../views/ProfileView.vue"),
        meta: {
            title: "Админ",
            headerClass: "_delivery",
            refName: "На главную",
            refTo: "/",
            menuDelivery: true,
            back: true,
        },
    },
    {
        path: "/certificates",
        name: "certificates",
        component: () => import("../views/CertificatesView.vue"),
        meta: {
            title: "Онлайн сертификаты",
            headerClass: "_certificates",
            refName: "На главную",
            refTo: "/",
        },
    },
    // переадресация старых страниц
    {
        path: "/restaurants/",
        redirect: "/restaraunts",
    },
    {
        path: "/delivery-terms/",
        redirect: "/delivery",
    },
    {
        path: "/categories/na-universitetskoy",
        redirect: "/menu",
    },
    {
        path: "/download_app",
        redirect: "/discounts",
    },
    {
        path: "/about/",
        redirect: "/about-us",
    },
    {
        path: "/login/",
        redirect: "/profile",
    },
    {
        path: "/jobs/",
        beforeEnter: () => {
            window.location.href = "https://rabota.tubatay.com/";
        },
        name: "jobs",
    },
    {
        path: "/opt/",
        redirect: "/contacts",
    },
    {
        path: "/for-suppliers/",
        redirect: "/contacts",
    },
    {
        path: "/requisites/",
        redirect: "/details",
    },
    {
        path: "/reviews/",
        redirect: "/contacts",
    },
    {
        path: "/menu/sertifikaty-i-podarki",
        redirect: "/certificates",
    },
    {
        path: "/catalog/",
        redirect: "/",
    },
    {
        path: "/search/",
        redirect: "/",
    },
    {
        path: "/cart/",
        redirect: "/",
    },
    {
        path: "/recipes/",
        redirect: "/",
    },
    {
        path: "/master-classes/",
        redirect: "/",
    },
    {
        path: "/iftar/",
        redirect: "/",
    },
    {
        path: "/menu/supy-1",
        redirect: "/menu",
    },
    {
        path: "/menu/kystyburgery",
        redirect: "/menu",
    },
    {
        path: "/categories/",
        redirect: "/menu",
    },
    {
        path: "/menu/vtorye-blyuda-1",
        redirect: "/menu",
    },
    {
        path: "/menu/vypechka",
        redirect: "/menu",
    },
    {
        path: "/menu/detskoe-menyu-2",
        redirect: "/menu",
    },
    {
        path: "/menu/kombo-1",
        redirect: "/menu",
    },
    {
        path: "/menu/novinki-1",
        redirect: "/menu",
    },
    {
        path: "/menu/deserty",
        redirect: "/menu",
    },
    {
        path: "/menu/supy",
        redirect: "/menu",
    },
    {
        path: "/menu/vtorye-blyuda",
        redirect: "/menu",
    },
    {
        path: "/menu/kurai",
        redirect: "/menu",
    },
    {
        path: "/menu/salaty",
        redirect: "/menu",
    },
    {
        path: "/menu/kombo",
        redirect: "/menu",
    },
    {
        path: "/menu/zavtraki",
        redirect: "/menu",
    },
    {
        path: "/menu/napitki-1",
        redirect: "/menu",
    },
    {
        path: "/menu/iftar-kombo",
        redirect: "/menu",
    },
    {
        path: "/catalog/polufabrikaty",
        redirect: "/menu",
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "active",
    linkExactActiveClass: "active",
    scrollBehavior() {
        return { top: 0 };
    },
});

router.beforeEach((to, from, next) => {
    document.title = "Tюбетей | " + to.meta.title;
    next();
});

export default router;
