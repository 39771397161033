<template>
    <div class="recom-products">
        <h5 class="recom-products__title">{{ title }}</h5>
        <div class="block-flex">
            <div class="souse" v-if="ifSouse" @click="isShowSouses = !isShowSouses">
                <div class="souse__img">
                    <img src="@/assets/img/souse.png" alt="souse" />
                </div>
                <h4 class="souse__title">Соусы</h4>
                <div class="souse__arrow">
                    <img
                        src="@/assets/img/svg/arrow-recom.svg"
                        alt="arrow"
                        :style="
                            isShowSouses
                                ? 'transform: matrix(-1, 0, 0, -1, 0, 0)'
                                : 'transform: matrix(1, 0, 0, 1, 0, 0)'
                        "
                    />
                </div>
            </div>
            <swiper
                :slides-per-view="1"
                :space-between="20"
                class="recom-products__products"
                :breakpoints="{
                    320: { spaceBetween: 5 },
                    769: { spaceBetween: 20 },
                }"
            >
                <swiper-slide class="recom-products__product" v-for="product in products" :key="product.id">
                    <div class="recom-products__container">
                        <div class="recom-products__img">
                            <div
                                class="backdrop-count"
                                v-if="product.count || (itemId(product) && itemPrCount(product) !== 0)"
                            >
                                <div class="count">{{ product.count || itemCount(product) }}</div>
                            </div>
                            <img :src="product.image" alt="product" />
                        </div>
                        <div class="recom-products__body">
                            <div class="recom-products__desc">
                                {{ product.intro_text ? product.intro_text : "Здесь скоро появится описание..." }}
                            </div>
                            <div
                                class="recom-products__price counter"
                                v-if="product.count || (itemId(product) && itemPrCount(product) !== 0)"
                            >
                                <btn-to-cart :symbol="'-'" @click="$emit('refMinusToCart', product)" />
                                <div class="recom-products__cost">
                                    {{ product.price * product.count || product.price * itemCount(product) }} ₽
                                </div>
                                <btn-to-cart :symbol="'+'" @click="$emit('refPlusToCart', product)" />
                            </div>
                            <div class="recom-products__price" v-else>
                                <div class="recom-products__cost">{{ product.price }} ₽</div>
                                <btn-to-cart :symbol="'+'" @click="$emit('refAddToCart', product)" />
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
        <swiper
            :slides-per-view="1.2"
            :space-between="20"
            :loop="false"
            class="recom-products__products add-souse"
            v-if="isShowSouses"
            :breakpoints="{
                320: { spaceBetween: 10, slidesPerView: 1 },
                769: { spaceBetween: 20, slidesPerView: 1.2 },
            }"
        >
            <swiper-slide class="recom-products__product" v-for="product in souses" :key="product.id">
                <div class="recom-products__container">
                    <div class="recom-products__img">
                        <div
                            class="backdrop-count"
                            v-if="product.count || (itemId(product) && itemPrCount(product) !== 0)"
                        >
                            <div class="count">{{ product.count || itemCount(product) }}</div>
                        </div>
                        <img :src="product.image" alt="product" />
                    </div>
                    <div class="recom-products__body">
                        <div class="recom-products__desc" v-html="product.real_name"></div>
                        <div
                            class="recom-products__price counter"
                            v-if="product.count || (itemId(product) && itemPrCount(product) !== 0)"
                        >
                            <btn-to-cart :symbol="'-'" @click="$emit('refMinusToCart', product)" />
                            <div class="recom-products__cost">
                                {{ product.price * product.count || product.price * itemCount(product) }} ₽
                            </div>
                            <btn-to-cart :symbol="'+'" @click="$emit('refPlusToCart', product)" />
                        </div>
                        <div class="recom-products__price" v-else>
                            <div class="recom-products__cost">{{ product.price }} ₽</div>
                            <btn-to-cart :symbol="'+'" @click="$emit('refAddToCart', product)" />
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
import BtnToCart from "@/components/UI/BtnToCart.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import axios from "axios";
export default {
    props: {
        title: {
            type: String,
            default: null,
        },
        ifSouse: {
            type: Boolean,
            default: false,
        },
    },
    components: { Swiper, SwiperSlide, BtnToCart },
    data() {
        return {
            products: [],
            inCart: [],
            categories: [],
            souses: [],
            isShowSouses: false,
            shop_code: localStorage.getItem("shop_code") ?? "it-park",
        };
    },
    setup() {
        // const onSwiper = (swiper) => {
        //     console.log(swiper);
        // };
        // const onSlideChange = () => {
        //     console.log("slide change");
        // };
        // return {
        //     onSwiper,
        //     onSlideChange,
        // };
    },
    methods: {
        itemId(product) {
            return this.cartItems.find((item) => product.id === item);
        },
        itemCount(product) {
            return Number(
                this.inCart
                    .map((item) => (product.id === item.id ? item.count : null))
                    .filter(Number)
                    .join()
            );
        },
        itemPrCount(product) {
            return (this.itemCount(product) + product.count - (this.itemCount(product) - product.count)) / 2;
        },
    },
    computed: {
        cartItems() {
            return this.inCart.map((item) => item.id);
        },
    },
    mounted() {
        if (localStorage.getItem("cart")) {
            let inCart = JSON.parse(localStorage.getItem("cart"));
            for (let i = 0; i < inCart.length; i++) {
                this.inCart.push(inCart[i]);
            }
        }
        //Товары
        axios
            .get(`https://admin.tcode.online/api/projects/tubatay/shops/${this.shop_code}/products`)
            .then((response) => {
                console.log(response.data);
                this.products = response.data.results;
            })
            .catch((error) => {
                console.log(error);
            });
        //Категории
        axios
            .get(`https://admin.tcode.online/api/projects/tubatay/shops/${this.shop_code}/categories`)
            .then((response) => {
                console.log(response.data);
                this.categories = response.data.results;
                for (let index = 0; index < this.categories.length; index++) {
                    this.selectedCategoryRealName = this.categories[index].real_name;
                    if (this.selectedCategoryRealName === "Соусы") {
                        this.selectedCategoryId = this.categories[index].id;
                    }
                }
                axios
                    .get(
                        `https://admin.tcode.online/api/projects/tubatay/shops/${this.shop_code}/products/?category=` +
                            this.selectedCategoryId
                    )
                    .then((response) => {
                        console.log(response.data.results);
                        this.souses = response.data.results;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    },
};
</script>

<style lang="scss" scoped>
.recom-products {
    margin-top: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    .add-souse {
        margin: 0 auto;
        padding: 0;
    }
    .block-flex {
        display: flex;
        align-content: center;
    }
    .souse {
        min-width: 86px;
        max-width: 86px;
        height: 144px;
        border-radius: 15px;
        overflow: hidden;
        box-shadow: var(--box-shadow);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        cursor: pointer;
        @media (max-width: 768px) {
            min-width: 64px;
            height: 104px;
        }
        &__img {
            width: inherit;
            height: 87.16px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__title {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #1f2120;
            text-align: center;
            margin-bottom: 10px;
            @media (max-width: 768px) {
                font-size: 12px;
                line-height: 16px;
            }
        }

        &__arrow {
            padding-bottom: 10px;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
            @media (max-width: 768px) {
                width: 10px;
                height: 15px;
            }
        }
        ~ .recom-products__products {
            margin-left: 15px;
            padding: 20px 0;
            @media (max-width: 768px) {
                margin-left: 5px;
                margin-right: 0;
            }
        }
    }
}
.recom-products__title {
    font-size: 16px;
    font-family: "Nunito Sans Bold";
    line-height: 24px;
    color: #1f2120;
    text-align: left;
}
@media (max-width: 768px) {
    .recom-products__title {
        font-size: 14px;
    }
}
.recom-products__products {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: -10px -20px 0;
    padding: 20px;
}
.recom-products__product {
    min-width: 344px;
    max-width: 344px;
    height: 144px;
    border-radius: 16px;
    -webkit-box-shadow: var(--box-shadow);
    box-shadow: var(--box-shadow);
    overflow: hidden;
}
@media (max-width: 768px) {
    .recom-products__product {
        min-width: 100%;
        max-width: 100%;
        height: 104px;
    }
}
.recom-products__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.recom-products__img {
    min-width: 144px;
    max-width: 144px;
    height: 144px;
    overflow: hidden;
    position: relative;
    .backdrop-count {
        position: absolute;
        width: 100%;
        height: 100%;
        backdrop-filter: brightness(0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 48px;
        line-height: 56px;
        color: #fff;
        font-family: "Nunito Sans Black";
        pointer-events: none;
        border-radius: 16px 0 0 16px;
    }
}
@media (max-width: 768px) {
    .recom-products__img {
        min-width: 104px;
        max-width: 104px;
        height: 104px;
    }
}
.recom-products__img img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}
.recom-products__body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 10px 15px;
    flex: 1 1 auto;
    justify-content: space-between;
    @media (max-width: 768px) {
        padding: 5px;
    }
}
.recom-products__desc {
    font-size: 14px;
    font-family: var(--font-main);
    line-height: 24px;
    color: #1f2120;
    text-align: left;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}
@media (max-width: 768px) {
    .recom-products__desc {
        font-size: 12px;
        font-family: "Nunito Sans ExtraLight";
        line-height: 16px;
    }
}
@media (max-width: 480px) {
    .recom-products__desc {
        font-size: 10px;
    }
}
.recom-products__price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.recom-products__cost {
    font-size: 18px;
    font-family: "Nunito Sans Bold";
    line-height: 24px;
    color: #1f2120;
}
@media (max-width: 768px) {
    .recom-products__cost {
        font-size: 14px;
    }
}
@media (max-width: 480px) {
    .recom-products__cost {
        font-size: 12px;
    }
}
.recom-products__btn {
    width: 40px;
    height: 40px;
    border-radius: 16px;
    font-size: 18px;
    font-family: "Nunito Sans Bold";
    line-height: 24px;
    color: #fff;
    background: var(--green);
}
@media (max-width: 768px) {
    .recom-products__btn {
        width: 32px;
        height: 32px;
        border-radius: 12px;
        font-size: 14px;
    }
}
</style>
