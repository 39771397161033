<template>
    <div class="btn-profile-block">
        <button class="btn-profile">
            <div class="btn-profile__icon"><img :src="require(`@/assets/img/icons/${img}`)" alt="coin" /></div>
            <div class="btn-profile__text">{{ btnName }}</div>
        </button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            btnName: "Профиль",
            img: "coin.webp",
        };
    },
};
</script>

<style>
@media (max-width: 768px) {
    .btn-profile-block {
        flex: 1 1 auto;
        display: flex;
        justify-content: flex-end;
        margin-right: 15px;
    }
}
.btn-profile {
    width: fit-content;
    height: 32px;
    border-radius: 20px;
    background-color: #d9f5e4;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 40px;
    padding: 0 10px;
}
.btn-profile:hover,
.btn-profile:focus {
    background-color: var(--green);
    transition: 0.2s ease 0.2s;
}
.btn-profile:hover .btn-profile__text,
.btn-profile:focus .btn-profile__text {
    color: #fff;
    transition: 0.2s ease 0.2s;
}
@media (max-width: 768px) {
    .btn-profile {
        width: fit-content;
        height: 28px;
        margin-left: 0;
        border-radius: 20px;
        padding: 0 10px;
    }
}
.btn-profile__icon {
    min-width: 22px;
    max-width: 22px;
    height: 22px;
    margin-right: 10px;
}
@media (max-width: 768px) {
    .btn-profile__icon {
        min-width: 20px;
        height: 20px;
        margin-right: 5px;
    }
}
.btn-profile__icon img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
}
.btn-profile__text {
    font-size: 14px;
    font-family: "Nunito Sans Bold";
    line-height: 22px;
    text-align: center;
    color: var(--green);
    font-family: "Nunito Sans SemiBold";
}
@media (max-width: 768px) {
    .btn-profile__text {
        font-size: 12px;
        font-family: "Nunito Sans SemiBold";
        line-height: 18px;
    }
}
</style>
