<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="popup _review" @click="$emit('close')">
        <div class="popupInner" @click.stop>
            <btn-close @click="$emit('close')" />
            <div class="popup__body">
                <h2 class="popup__title">Оставьте ваш отзыв</h2>
                <div class="popup__raiting">
                    <div
                        class="popup__ech"
                        v-for="(star, index) in starLimit"
                        :key="index"
                        @click="setRatingStar(star)"
                        @mouseover="overRatingStar(star)"
                        @mouseleave="active = 0"
                    >
                        <img
                            :src="require(`@/assets/img/${active >= star || rating >= star ? ech_active : ech}`)"
                            alt="ech"
                        />
                    </div>
                </div>
                <div class="popup__block-form">
                    <form class="popup__form" @submit.prevent>
                        <input type="hidden" name="rating" v-model="rating" />
                        <div class="popup__input">
                            <input
                                type="text"
                                name="name"
                                placeholder="Как вас зовут?"
                                maxlength="50"
                                v-model="form.name"
                            />
                        </div>
                        <div class="block-flex">
                            <div class="popup__input block-input">
                                <span class="seven">+7</span>
                                <input
                                    name="phone"
                                    v-model="form.phone"
                                    v-mask="'### ### ## ##'"
                                    placeholder="900 000 00 00"
                                    @input="inputPhone"
                                />
                            </div>
                            <div class="popup__input">
                                <vue-date-picker
                                    v-model="form.date"
                                    :format="format"
                                    model-type="dd.MM.yyyy"
                                    locale="ru"
                                    type="date"
                                    cancelText=""
                                    selectText="Выбрать"
                                    placeholder="Дата заказа"
                                    month-name-format="long"
                                    :enable-time-picker="false"
                                    disable-year-select
                                    auto-apply
                                    id="date"
                                    :clearable="false"
                                />
                            </div>
                        </div>
                        <input type="hidden" v-model="form.delivery" />
                        <div class="block-tabs">
                            <button
                                class="btn-tab"
                                v-for="(tab, index) in tabs"
                                :key="tab.id"
                                :class="btn_select === index ? '_active' : ''"
                                @click="(btn_select = index), (form.delivery = tab.method)"
                            >
                                {{ tab.name }}
                            </button>
                        </div>
                        <div class="popup__toggle" :class="{ _active: isToggle }" v-if="form.delivery === 'by_pickup'">
                            <input type="hidden" name="address" v-model="form.address" />
                            <button class="btn-toggle" @click="isToggle = !isToggle">
                                {{ form.address ? form.address : "Адрес ресторана" }}
                                <img src="@/assets/img/svg/arrow-down.svg" alt="arrow" />
                            </button>
                            <div class="popup__items">
                                <div
                                    v-for="shop in shops"
                                    :key="shop.id"
                                    :value="shop.name"
                                    @click="(form.address = shop.name), (isToggle = false)"
                                    class="popup__item"
                                >
                                    {{ shop.name }}
                                </div>
                            </div>
                        </div>
                        <template v-if="!isToggle">
                            <div class="popup__input textarea">
                                <textarea
                                    name="comment"
                                    id="comment"
                                    placeholder="Напишите свой отзыв"
                                    v-model="form.comment"
                                ></textarea>
                            </div>
                            <div class="success" v-if="success">{{ success }}</div>
                            <div class="error" v-if="error">{{ error }}</div>
                            <div class="popup__agree">
                                <p>Указывая номер телефона, вы принимаете</p>
                                <a href="Политики_конфиденциальности_на_политику_обработки.pdf" target="_blank"
                                    >политикой обработки персональных данных и системой лояльности</a
                                >
                            </div>
                        </template>
                        <button
                            type="submit"
                            name="send"
                            class="btn send"
                            :disabled="checkForm ? false : true"
                            @click="sendRequest()"
                        >
                            Отправить
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BtnClose from "../UI/BtnClose.vue";
import axios from "axios";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
export default {
    components: { BtnClose, VueDatePicker },
    data() {
        return {
            now: new Date(),
            isClick: false,
            rating: 0,
            active: 0,
            starLimit: 5,
            ech: "ech.png",
            ech_active: "ech_active.png",
            form: {
                name: null,
                comment: null,
                phone: "",
                date: "",
                address: "",
                delivery: "by_pickup",
            },
            error: null,
            success: null,
            shops: [],
            isToggle: false,
            tabs: [
                { id: 1, name: "Доставка", method: "by_delivery" },
                { id: 2, name: "Ресторан", method: "by_pickup" },
            ],
            btn_select: 1,
            format: (date) => {
                const day = String(date.getDate()).padStart(2, "0");
                const month = String(date.getMonth() + 1).padStart(2, "0");
                const year = date.getFullYear();
                return `${day}.${month}.${year}`;
            },
        };
    },
    methods: {
        overRatingStar(star) {
            this.active = star;
        },
        setRatingStar(star) {
            console.log(star);
            this.rating = star;
            this.error = "";
        },
        inputPhone() {
            this.error = "";
        },
        sendRequest() {
            if (this.rating > 0) {
                axios
                    .post(`https://admin.tcode.online/api/projects/tubatay/site-requests/`, {
                        page: "Отзывы с сайта Тюбетей Общее",
                        purpose: this.form.delivery === "by_pickup" ? "Форма отзыва" : "Форма обратной связи",
                        name: this.form.name,
                        comment: this.form.comment,
                        phone: `+7 ${this.form.phone}`,
                        rating: this.rating,
                        visit_date: this.form.date,
                        shop_name: this.form.address,
                        delivery: this.form.delivery,
                    })
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.success) {
                            this.success = "Спасибо. Ваш отзыв успешно отправлен.";
                            setTimeout(() => {
                                this.$emit("close");
                            }, 2000);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        if (error.response.data.error === "Some fields required to be filled") {
                            this.error = "Неправильно указан номер телефона";
                        }
                    });
            } else {
                this.error = "Вы не поставили оценку";
                this.overRatingStar(5);
                setTimeout(() => {
                    this.overRatingStar(0);
                }, 1000);
            }
        },
    },
    computed: {
        nowDate() {
            return this.now.toLocaleDateString().split(".").reverse().join("-");
        },
        checkForm() {
            return (
                this.form.name &&
                this.form.phone.length > 12 &&
                this.form.date &&
                this.form.comment &&
                (this.form.delivery === "by_pickup" ? this.form.address : this.form.delivery)
            );
        },
    },
    mounted() {
        axios
            .get("https://admin.tcode.online/api/projects/tubatay/shops/")
            .then((response) => {
                console.log(response.data);
                this.shops = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    },
};
</script>

<style lang="scss">
.dp__input_wrap {
    height: 56px;
    svg {
        display: none;
    }
}
</style>
<style lang="scss" scoped>
@import url("@/assets/scss/popup.scss");
.popup {
    .popupInner {
        max-width: 464px;
    }
    &._review {
        .btn-close {
            position: absolute;
            top: 15px;
            right: 15px;
        }
    }
    &__raiting {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
    &__ech {
        width: 40px;
        height: 40px;
        margin: 0 2.5px;
        cursor: pointer;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        @media (max-width: 768px) {
            margin: 0 5px;
        }
    }
    .btn[disabled] {
        background: #c8c8c8;
        color: #7a7a7a;
    }
}
.popup__input {
    &.block-input {
        border: 1px solid #c8c8c8;
        border-radius: 12px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        input {
            border: none;
            padding: 0 0 0 10px;
        }
        .seven {
            position: relative;
            left: 0;
        }
    }
}
.popup__toggle {
    border: 1px solid #c8c8c8;
    border-radius: 12px;
    padding: 0 10px;
    margin-top: 10px;
    height: 56px;
    overflow: hidden;
    &._active {
        height: 56px * 4;
        .btn-toggle {
            img {
                transform: matrix(1, 0, 0, -1, 0, 0);
            }
        }
    }
    .btn-toggle {
        width: 100%;
        height: 56px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #262626;
        font-family: var(--font-main);
        padding: 0 5px;
        background: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
.popup__items {
    border-radius: 0 0 12px 12px;
    overflow-x: hidden;
    height: 56px * 3;
    padding: 0 5px;
    &::-webkit-scrollbar {
        width: 2px;
    }
    &::-webkit-scrollbar-track {
        background: #e2e2e2;
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--green);
        border-radius: 5px;
        cursor: pointer;
    }
    &::-webkit-scrollbar-thumb:hover {
        opacity: 0.5;
    }
}
.popup__item {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #262626;
    font-family: var(--font-main);
    text-align: left;
    height: 56px;
    display: flex;
    align-items: center;
    border-top: 1px solid #e2e2e2;
    cursor: pointer;
    &:hover,
    &:focus {
        background: #eee;
        transition: 0.2s ease 0.2s;
    }
}
.error {
    margin-top: 20px;
}
.success {
    color: var(--green) !important;
    margin-top: 20px;
    font-size: 16px;
}
.block-flex {
    display: flex;
    justify-content: space-between;
    .popup__input {
        width: 49%;
        flex: 0 0 49%;
    }
}
.block-tabs {
    width: 100%;
    height: 56px;
    border-radius: 16px;
    background: #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    padding: 0 5px;
    .btn-tab {
        width: 49%;
        flex: 0 0 49%;
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        background: none;
        &._active {
            background: #fff;
            color: var(--green);
        }
        &:hover,
        &:focus {
            background: #fff;
            color: var(--green);
            transition: 0.2s ease 0.2s;
        }
    }
}
</style>
